
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'gatsby'

import Symbol from 'utils/Symbol'

import { newMessagesAtom } from 'utils/atoms'


export default function RoomsButton() {

	const newMessages = useRecoilValue(newMessagesAtom)

	return <div id='RoomsButton'>

		<Link to='/chat/#rooms' className={`button ${newMessages ? 'primary' : ''}`}>
			<Symbol id='leftArrow' /> Rooms { !!newMessages ? `(${newMessages})` : '' }
		</Link>

	</div>
}


