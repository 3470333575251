
import React from 'react'
import QRCode from 'qrcode.react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
	getFirestore, getDocs, query, collection, where, writeBatch, addDoc, serverTimestamp, limit
} from 'firebase/firestore'

import firebase from 'utils/firebase'
import useStatus from 'utils/useStatus'
import Spinner from 'utils/Spinner'
import Symbol from 'utils/Symbol'

import { userAtom, inviteCodesAtom } from 'utils/atoms'

const url = code => `https://nameless.im/invite/${code}/`


export default function Invites({ 
	roomId,
	// close
}) {

	const { uid } = useRecoilValue(userAtom)
	const [status, setStatus] = useStatus()
	const [code, setCode] = useRecoilState(inviteCodesAtom(roomId))
	const [isCopied, setCopied] = React.useState(false)

	async function copyToClipboard(text) {
		try {
			await navigator.clipboard.writeText(text)
			setCopied(true)
		} catch (error) { alert("Copy failed.") }
	}

	async function handleGenerate() {
		if (!!code && !window.confirm("Current invite code will not work anymore. Continue?")) return false
		setStatus(true)
		const firestore = getFirestore(firebase)
		// Delete previous invites
		const prevInvitesSnap = await getDocs(query(
			collection(firestore, 'invites'),
			where('roomId', '==', roomId),
			where('uid', '==', uid),
		))
		if (!prevInvitesSnap.empty) {
			const batch = writeBatch(firestore)
			prevInvitesSnap.forEach(doc => batch.delete(doc.ref))
			await batch.commit()
		}
		// Generate new invite
		const inviteRef = await addDoc(collection(firestore, 'invites'), {
			uid,
			roomId,
			created: serverTimestamp()
		})
		setCode(inviteRef.id)
		setStatus()
	}


	React.useEffect(() => {
		async function fetchInvite() {
			console.log('fetchInvite')
			const snap = await getDocs(query(
				collection(getFirestore(firebase), 'invites'),
				where('roomId', '==', roomId),
				where('uid', '==', uid),
				limit(1)
			))
			if (!snap.empty) setCode(snap.docs[0].id)
		}
		if (!code) fetchInvite()
	}, [roomId, uid, setCode, code])


	return <div id='Invites'>

		{/* <button className='close' onClick={() => close()}>
			close
		</button> */}

		<h3>Invite</h3>

		{ !!code && <> 
			<p>This room can only be accessed using the link below. Only you can see and create the code.</p>
			<p className='inviteLink'>
				<input type='text'
					value={url(code)}
					readOnly 
				/>
				<button onClick={() => copyToClipboard(url(code))} className='symbol'>
					<Symbol id={ isCopied ? 'copyCheck' : 'copy' } />
				</button>
			</p>
			<p>
				<button onClick={() => handleGenerate()} disabled={status.working}>
					<small>Generate a new code</small>
				</button>
				{/* { !!code && <><br /><small>Current code will not work anymore</small></> } */}
			</p>
			<div className='qrCode'>
				<QRCode value={url(code)} 
					renderAs='svg' 
					bgColor='transparent'
					size={96}
					// imageSettings={{ excavate: true }}
				/>
			</div>
		</> }

		{ !code && <>
			<p>
				<button onClick={() => handleGenerate()} disabled={status.working}>
					Generate an invite code
				</button>
			</p>
		</>}
		
		{ status.working && <Spinner>Generating...</Spinner> }
		{ status.error }

	</div>
}

