
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'gatsby'

import RelativeTime from 'utils/RelativeTime'
import Symbol from 'utils/Symbol'

import { roomsAtom, lastReadAtom } from 'utils/atoms'


export default function ChatNav({ roomId }) {

	const rooms = useRecoilValue(roomsAtom)

	return <nav id='ChatNav'>

		{ rooms.map((room, index) =>
			<RoomTab key={room.id}
				room={room}
				tabIndex={index}
				active={room.id === roomId}
			/>
		)}
		
		<Link to='/create/' id='NewRoomLink'>Create a new room</Link>

	</nav>
}


const RoomTab = ({ 
	room, 
	tabIndex, 
	active,
}) => {

	const lastRead = useRecoilValue(lastReadAtom(room.id))
	const hasNew = room.updated?.seconds && lastRead < room.updated.seconds

	return <Link
		to={`/chat/${room.id}/`} 
		className={`ChatNavLink ${active ? 'active' : ''}`}
		role='menuitem' 
		tabIndex={tabIndex}
	>

		<strong>
			{ (hasNew && !active) && <span className='emoji'>🆕</span> }{ room.name } <span className='users'><Symbol id='users' />{ room.users.length }</span>
		</strong>
		
		{ !!room.snippet && <div className='snippet'>{ room.snippet }{ room.snippet.length > 50 ? '...' : '' }</div> }

		<div className='time'>
			<RelativeTime date={room.updated} />
		</div>
		
	</Link>
}

// 🔴  ❇️  ❗
