
import React from 'react'
// import dayjs from 'dayjs'
// import { useSetRecoilState } from 'recoil'
import { 
	getFirestore, collection, doc, addDoc, updateDoc, serverTimestamp 
} from 'firebase/firestore'

import firebase from 'utils/firebase'
import useStatus from 'utils/useStatus'

// import { lastReadAtom } from 'utils/atoms'


export default function Room({ roomId }) {

	const [status, setStatus] = useStatus()
	const inputRef = React.useRef(null)
	// const setLastRead = useSetRecoilState(lastReadAtom(roomId))
	
	async function sendMessage() {
		let message = `${inputRef.current.value || ''}`.trim()
		if (!message.length) return
		try {
			setStatus()
			const firestore = getFirestore(firebase)
			inputRef.current.value = ''
			const msgRef = await addDoc(collection(firestore, 'rooms', roomId, 'messages'), {
				text: message,
				created: serverTimestamp()
			})
			const roomRef = doc(firestore, 'rooms', roomId)
			await updateDoc(roomRef, {
				snippet: message.substring(0, 60).trim(),
				updated: serverTimestamp(),
				latest: msgRef.id,
			})
			// setLastRead(dayjs().unix())
		} catch (error) { setStatus({ error })	}
	}

	return <div id='ChatInput'>

		{ status.error }

		<textarea 
			ref={inputRef}
			name='message'
			rows='2'
			defaultValue=''
			aria-label='Message'
			onKeyPress={e => {
				if (e.key === 'Enter') {
					e.preventDefault()
					sendMessage()
				}
			}}
		/>

		<button onClick={() => sendMessage()}>Send<br />⏎</button>

	</div>
}
