
import React from 'react'
import { useRecoilValue } from 'recoil'
import { navigate } from 'gatsby'

import Meta from 'layout/Meta'
import getFunction from 'utils/functions'
import useStatus from 'utils/useStatus'
import Invites from 'chat/Invites'

import { userAtom, roomAtom, roomsAtom } from 'utils/atoms'


export default function Room({ roomId }) {

	const [status, setStatus] = useStatus()
	const room = useRecoilValue(roomAtom(roomId))
	const rooms = useRecoilValue(roomsAtom)
	const [showInvites, setShowInvites] = React.useState(false)
	const { uid } = useRecoilValue(userAtom)
	
	const handleLeave = async (event) => {
		event.preventDefault()
		if (!window.confirm("Do you really want to leave?")) return false
		setStatus(true)
		try {
			const leaveRoomFunc = await getFunction('leaveRoom')
			const otherRooms = rooms.filter(r => r.id !== roomId)
			await leaveRoomFunc({ roomId: room.id })
			setStatus()
			return navigate(otherRooms.length ? `/chat/${otherRooms[0].id}/` : '/chat/')
		} catch (error) { 
			console.error(error)
			setStatus({ error }) 
		}
	}

	if (!room) return null

	const isAdmin = room.admin === uid
	const userCount = room.users.length

	return <header 
		id='ChatMenu' 
		className={showInvites ? 'roomShowInvites' : 'roomHideInvites'}
	>

		<Meta title={room.name} />
		
		<button onClick={handleLeave} disabled={status.working} id='RoomLeaveBtn'>
			Leave
		</button>

		<h1 id='RoomName'>{ room.name }</h1>

		{ status.error }

		<div id='RoomUsers'>
			<p id='RoomUsersCount'>
				{ userCount === 1 ? 'Just you in here' : `${userCount} people in room` }{ Array.isArray(room.facebookProfiles) ? ':' : '.' }
			</p>
			{ Array.isArray(room.facebookProfiles) && 
				<ul id='RoomProfiles'>
					{ room.facebookProfiles.map(p => 
						<li key={p.id}>{p.name}</li>
					) }
				</ul>
			}
			<button id='RoomInvitesBtn'
				onClick={() => setShowInvites(p => !p)}
			>
				{ showInvites ? 'Hide invite code' : 'Show invite code' }
			</button>
		</div>


		{ isAdmin &&
			<Invites roomId={room.id} close={() => setShowInvites(false)} /> }

	</header>
}
