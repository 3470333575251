
import React from 'react'

import RoomMenu from 'chat/RoomMenu'
import Messages from 'chat/Messages'
import RoomInput from 'chat/RoomInput'


export default function Room({ roomId }) {

	return <>

		<RoomMenu roomId={roomId} />
		
		<Messages roomId={roomId} />

		<RoomInput roomId={roomId} />
		
	</>
}
