
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)


export default function RelativeTime({ date }) {
	return !!date 
		? dayjs(date.toDate()).fromNow()
		: "a few seconds ago"
}

