
import React from 'react'
import { useRecoilState } from 'recoil'
import dayjs from 'dayjs'
import { useScrollRestoration } from 'gatsby'
import { 
	getFirestore, query, collection, limit, orderBy, onSnapshot, startAfter, getDocs
} from 'firebase/firestore'

import RelativeTime from 'utils/RelativeTime'
import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'

import { messagesAtom, lastReadAtom } from 'utils/atoms'

const BATCHSIZE = 25


export default function Messages({
	roomId
}) {

	const [isLoading, setLoading] = React.useState(false)
	const [error, setError] = React.useState(null)
	// const messagesRef = React.useRef(false)
	const [messages, setMessages] = useRecoilState(messagesAtom(roomId))
	const [isOldestReached, setOldestReached] = React.useState(null)
	const [lastRefresh, setLastRefresh] = React.useState(null)
	const [lastRead, setLastRead] = useRecoilState(lastReadAtom(roomId))
	const scrollResto = useScrollRestoration(roomId)

	React.useEffect(() => {
		// console.log("Subscribe to /messages")
		const firestore = getFirestore(firebase)
		const q = query(
			collection(firestore, `rooms/${roomId}/messages`), 
			orderBy('created', 'desc'),
			limit(BATCHSIZE)
		)
		return onSnapshot(q, snap => {
			const msgs = []
			snap.forEach(doc => msgs.push({ id: doc.id, ...doc.data() }))
			setMessages(prev => {
				if (!prev || !msgs.length) return msgs.reverse()
				const oldestNewId = msgs[msgs.length - 1].id
				const prevIdx = prev.findIndex(m => m.id === oldestNewId)
				return prevIdx > -1
					?	prev.slice(0, prevIdx).concat(msgs.reverse())
					:	msgs.reverse()
			})
		})
	}, [roomId, setMessages])


	const loadMore = async () => {
		if (!messages || isLoading || isOldestReached || messages.length < BATCHSIZE) return
		// console.log('load more')
		setLoading(true)
		setError(null)
		try {
			const lastMessage = messages[0]
			if (!lastMessage) return
			const q = query(
				collection(getFirestore(firebase), `rooms/${roomId}/messages`),
				orderBy('created', 'desc'),
				startAfter(lastMessage.created),
				limit(BATCHSIZE)
			)
			const snap = await getDocs(q)
			const payload = []
			snap.forEach(doc => payload.push({ id: doc.id, ...doc.data() }))
			if (payload.length < BATCHSIZE) setOldestReached(true)
			setMessages(prev => [...payload.reverse(), ...prev])
		} catch (error) { setError(`${error.message} [${error.code}]`) }
		setLoading(false)
	}


	const handleScroll = event => {
		const element = event.target
		// console.log(messagesRef.current.scrollHeight, messagesRef.current.clientHeight, messagesRef.current.scrollTop, messagesRef.current.offsetHeight)
		if (element.scrollTop === 0) {
			loadMore()
		} else if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			console.log('end')
			// setLastRead(dayjs().unix())
		}
	}

	React.useEffect(() => {
		const { scrollHeight, clientHeight, scrollTop} = scrollResto.ref.current
		if (scrollHeight - (clientHeight * 2) < scrollTop) 
			scrollResto.ref.current.scrollTop = scrollHeight
	}, [messages, scrollResto.ref])

	React.useEffect(() => {
		const interval = setInterval(() => {
			setLastRefresh(Date.now())
		}, 20000)
		return () => clearInterval(interval)
	}, [])

	React.useEffect(() => {
		// console.log('set last read', roomId)
		return function() {
			setLastRead(dayjs().unix())
		}
		// if (Array.isArray(messages) && messages.length > 0) {
		// 	const { created } = messages[messages.length-1]
		// 	console.log(created.seconds)
		// 	setLastRead(created.seconds)
		// }
	}, [roomId, setLastRead])

	return <div id='ChatMessages' 
		onScroll={handleScroll} 
		// ref={messagesRef}
		data-lastrefresh={lastRefresh}
		{...scrollResto}
	>

		{ (messages === null || isLoading) && <Spinner>Loading messages</Spinner> }

		{ Array.isArray(messages) && messages.map(message => 
			<Message key={message.id}
				message={message}
				isNew={!lastRead ? false : lastRead < (message.created?.seconds || 0)}
			/>
		) }

		{ error && <div className='error'>ERROR: { error }</div> }

	</div>
}


const Message = ({ 
	message,
	isNew
}) => {
	return <div className={`message ${isNew ? 'new' : ''}`}>
	
		<span className='time'><RelativeTime date={message.created} /></span>
	
		<p>{ message.text }</p>
		
	</div>
}
