
import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { navigate } from 'gatsby'
import { 
	getFirestore, query, collection, where, orderBy, onSnapshot 
} from 'firebase/firestore'

import Meta from 'layout/Meta'
import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'
import ReqAuth from 'user/ReqAuth'
import Room from 'chat/Room'
import ChatNav from 'chat/ChatNav'
import RoomsButton from 'chat/RoomsButton'

import { userAtom, roomsAtom } from 'utils/atoms'


export default function ChatPage({ location, params }) {
	return <ReqAuth>
		<Chat roomId={params['*']} redirect={location.hash !== '#rooms'} />
	</ReqAuth>
}


function Chat({ roomId, redirect }) {

	const [rooms, setRooms] = useRecoilState(roomsAtom)
	const { uid } = useRecoilValue(userAtom)

	React.useEffect(() => {
		const q = query(
			collection(getFirestore(firebase), 'rooms'), 
			where('users', 'array-contains', uid),
			orderBy('updated', 'desc')
		)
		return onSnapshot(q, snap => {
			const rooms = []
			snap.forEach(doc => rooms.push({
				id: doc.id,
				...doc.data(),
			}))
			setRooms(rooms)
		})
	}, [uid, setRooms])

	React.useEffect(() => {
		if (redirect && !roomId && Array.isArray(rooms) && !!rooms.length && !!rooms[0]?.id)
			navigate(`/chat/${rooms[0].id}/`)
	}, [rooms, roomId, redirect])

	if (!Array.isArray(rooms)) return <Spinner fullscreen>Loading...</Spinner>

	return <div id='Chat' className={!roomId ? 'ChatRoomsList' : ''}>

		<Meta title="Chat" />

		{ roomId && <RoomsButton /> }

		<ChatNav roomId={roomId} />

		{ roomId && <Room roomId={roomId} /> }

	</div>
}
